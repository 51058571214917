/**
 * Created by alan on 17-4-30.
 */
import moment from "moment-timezone";

const DataUtil = {

  getUrl() {
    let url = window.global_config.baseURL;
    return url;
  },

  addZero(d) {
    d = parseInt(d);
    if (d < 10) {
      return "0" + d;
    } else {
      return d;
    }
  },
  dateTransform(data) {
    if (data == null) {
      return "";
    } else {
      moment.tz.setDefault("Asia/Kolkata");
      return moment(data).format("YYYY-MM-DD HH:mm:ss");
    }
  },
  dateTransformYMD(date) {
    if (date == null) {
      return "";
    } else {
      // moment.tz.setDefault("Asia/Kolkata");
      return moment(date).format("YYYY-MM-DD");
    }
  },
  formatNum(str) {

    var newStr = "";
    var count = 0;
    str = (str || 0).toString();

    // 当数字是整数
    if (str.indexOf(".") == -1) {
      for (var i = str.length - 1; i >= 0; i--) {
        if (count % 3 == 0 && count != 0) {
          newStr = str.charAt(i) + "," + newStr;
        } else {
          newStr = str.charAt(i) + newStr;
        }
        count++;
      }
      str = newStr;
      return str;
    } else {
      for (var k = str.indexOf(".") - 1; k >= 0; k--) {
        if (count % 3 == 0 && count != 0) {
          newStr = str.charAt(k) + "," + newStr;
        } else {
          newStr = str.charAt(k) + newStr;
        }
        count++;
      }
      str = newStr + (str + "00").substr((str + "00").indexOf("."), 3);
      return str;
    }
  },
  isMobile(param) {
    return /^[1][34578][0-9]{9}$/i.test(param);
  },
  isInteger(num) {
    return /^[0-9]\d*$/i.test(num);
  },
  isNumber(param) {
    return isNaN(param);
  },
  str2Json(param) {
    return JSON.parse(param);
  },
  setStorage(key, value) {
    return localStorage.setItem(key, value);
  },
  getStorage(key) {
    return localStorage.getItem(key);
  },
  clearStorage(key) {
    localStorage.removeItem(key);
  },
  userUtil(name, param) {
    if (param === "") {
      return this.clearStorage(name);
    }
    if (param) {
      return this.setStorage(name, param);
    } else {
      return this.getStorage(name);
    }
  },

  systemName(param) {
    return this.userUtil("systemName", param);
  },
  hidePhone(val) {
    return val.substr(0, 3) + "****" + val.substr(7);
  },


  ticket(param) {
    return this.userUtil("ticket", param);
  },
  visitor(param) {
    return this.userUtil("visitor", param);
  },

  /**
   * json转string*/
  json2Str(param) {
    return JSON.stringify(param);
  },
  savePermission(param) {
    let permission = this.json2Str(param);
    return this.userUtil("adminPermission", permission);
  },


  /**
   * 用户的userId*/
  id(param) {
    return this.userUtil("adminId", param);
  },

  /**
   * */
  uuid(param) {
    return this.userUtil("adminUuid", param);
  },

  /**
   * 用户的姓名*/
  userName(param) {
    return this.userUtil("adminName", param);
  },
  name(param) {
    return this.userUtil("name", param);
  },

  /**
   * 获取用户url权限*/
  getPermission() {
    return this.str2Json(this.userUtil("adminPermission"));
  },
};
export default DataUtil;
