/*eslint-disable */
import axios from 'axios'
import DataUtil from '../config/dataUtil'
// create an axios instance
// let url = window.global_config.baseURL;
const service = axios.create({
  baseURL: "",
  timeout: 50000
})
axios.defaults.withCredentials = true;
// axios.defaults.headers.post['Content-Type'] = 'application/json';
// 请求拦截器
service.interceptors.request.use((config) => {

  config.data.ticket = DataUtil.ticket() ;

  config.data.visitor = DataUtil.visitor();
  return config
}, err => {
  return Promise.reject(err)
})
// 响应拦截器
service.interceptors.response.use(
  response => {
    return response.data
  },
  error => {
    console.log('err' + error) // for debug
    return Promise.reject(error)
  }
)

export default service
