<template>
  <section>
    <div class="loginBg">
      <div class="login_form">
        <div class="login_title">后台管理系统</div>
        <form>
          <div class="login_input">
            <span class="long_string"></span>
            <img class="img_size" src="../assets/img/phone.png" alt />
            <input
              type="text"
              v-model="loginForm.username"
              maxlength="11"
              placeholder="请输入手机号"
            />
          </div>
          <div class="login_input">
            <span class="long_string"></span>
            <img class="img_size" src="../assets/img/changepassword.png" alt />
            <input
              type="password"
              v-model="loginForm.password"
              placeholder="请输入密码"
              autocomplete="off"
            />
          </div>
          <div class="login_input_code">
            <div class="login_code">
              <span class="long_string"></span>
              <img class="img_size" src="../assets/img/code.png" alt />
              <input
                type="text"
                v-model="loginForm.code"
                placeholder="请输入验证码"
                maxlength="8"
              />
            </div>
            <div class="login_code_img" @click="changeImg">
              <img :src="img" alt />
            </div>
          </div>
          <div class="btn" @click="login" :loading="logining">登录</div>
        </form>
      </div>
    </div>

    <!-- <ml-change-password ref="changePassword" changeType="1" :username="loginForm.username"></ml-change-password> -->
  </section>
</template>
<script>
import http from "../api/login";
import DataUtil from "../utils/config/dataUtil";
export default {
  data() {
    return {
      loginForm: {
        username: "",
        password: "",
        code: ""
      },
      codeImgUrl: "",
      img: "",
      logining: false,
      sessionId: "",
    };
  },
  methods: {

    // 切换验证码图片
    changeImg() {
      this.getImgCode();
    },
    getImgCode() {
      let _data = {};
      http.getImgCode(_data).then(res => {
        this.logining = false;
        if (res.code == "0000") {
          this.img = res.data.verifyCode;
          this.sessionId = res.data.sessionId;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    login() {
      this.logining = true;
      let userMobile = this.loginForm.username;
      let userPassword = this.loginForm.password;
      let code = this.loginForm.code;
      if (userMobile.length == "") {
        this.$message.error("请输入手机号");
        return;
      }
      if (userPassword.length == 0) {
        this.$message.error("请输入密码");
        return;
      }
      if (userPassword.length < 6) {
        this.$message.error("密码长度不少于6位");
        return;
      }
      if (code.length == 0) {
        this.$message.error("请输入验证码");
        return;
      }
      let _data = {
        cellphone: userMobile,
        password: userPassword,
        verifyCode: code,
        sessionId: this.sessionId,
      };
      http.login(_data).then(res => {
        this.logining = false;
        if (res.code == "0000") {
          DataUtil.ticket(res.data.ticket);
          DataUtil.visitor(res.data.visitor);
          DataUtil.name(res.data.name);
          this.$router.push("/Home");
        } else {
          this.$message.error(res.message);
        }
      });
    }
  },
  mounted: function() {
    this.$store.state.openTab = [];
    this.getImgCode();
  },
  components: {}
};
</script>
<style scoped>
.loginBg {
  width: 100%;
  height: 100vh;
  background-color: #3d3f42;
  /*background: url("../assets/img/loginBg.png");*/
  background-size: 100% 100%;
  position: relative;
}
.login_form {
  width: 556px;
  height: 450px;
  background: rgba(245, 245, 245, 1);
  opacity: 0.8704000000000001;
  color: #666;
  position: absolute;
  top: 50%;
  left: 50%;
  right: 422px;
  transform:translate(-50%,-50%);
  box-sizing: border-box;
  padding: 0 58px;
  padding-top: 36px;
  border-radius: 10px;
}

.login_title {
  margin: 0;
  text-align: center;
  font-size: 28px;
  font-weight: 400;
  line-height: 40px;
  color: rgba(51, 51, 51, 1);
  margin-bottom: 37px;
}
.login_input {
  width: 100%;
  height: 54px;
  position: relative;
  box-sizing: border-box;
  margin-bottom: 17px;
  border-radius: 3px;
  overflow: hidden;
}
.login_input select {
  width: 100%;
  height: 54px;
  line-height: 54px;
  border: none;
  padding-left: 79px;
  box-sizing: border-box;
  font-family: PingFangSC-Light, PingFang SC;
  appearance: none;
  outline: none;
  color: #666;
}
.login_input select option {
  width: 100%;
  height: 54px;
  line-height: 54px;
  border: none;
  padding-left: 79px;
  box-sizing: border-box;
  font-family: PingFangSC-Light, PingFang SC;
  appearance: none;
  outline: none;
  color: #666;
}
.login_input_code {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 17px;
}
.login_code {
  width: 320px;
  height: 54px;
  position: relative;
  box-sizing: border-box;
  border-radius: 3px;
  overflow: hidden;
}
.login_code input {
  width: 100%;
  height: 54px;
  line-height: 54px;
  border: none;
  padding-left: 79px;
  box-sizing: border-box;
  font-family: PingFangSC-Light, PingFang SC;
  background: #fff;
}
.login_code_img {
  width: 100px;
  height: 54px;
  background: #8a8a8a;
}
.long_string {
  width: 1px;
  height: 40px;
  background: #d1d1d1;
  position: absolute;
  top: 7px;
  left: 59px;
}
.img_size {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 15px;
  left: 18px;
}
.login_input input {
  width: 100%;
  height: 54px;
  line-height: 54px;
  border: none;
  padding-left: 79px;
  box-sizing: border-box;
  font-family: PingFangSC-Light, PingFang SC;
}
.btn {
  margin-top: 29px;
  width: 100%;
  height: 54px;
  background: #e3ab00;
  box-shadow: 0px 0px 0px 0px rgba(6, 56, 46, 1);
  border-radius: 3px;
  line-height: 54px;
  text-align: center;
  color: #fff;
  cursor: pointer;
}
.imgSize {
  width: 40px;
  height: 40px;
  display: block;
}
.block {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 30px;
  right: 20px;
}
input:focus {
  outline: none;
}
.login_input input:active {
  border: none;
}
.login_code_img img {
  width: 100%;
  height: 100%;
  display: block;
}
::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: rgba(153, 153, 153, 1);
  font-size: 16px;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: rgba(153, 153, 153, 1);
  font-size: 16px;
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: rgba(153, 153, 153, 1);
  font-size: 16px;
}
:-moz-placeholder {
  /* Firefox 18- */
  color: rgba(153, 153, 153, 1);
  font-size: 16px;
}
</style>
