import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    openTab: [], // 所有打开的路由
    activeIndex: "/Home" // 激活状态
  },
  mutations: {

    // 添加tabs
    ADD_TABS(state, data) {
      this.state.openTab.push(data);
    },

    // 删除tabs
    DELETE_TABS(state, route) {
      let index = 0;
      for (let option of state.openTab) {
        if (option.route === route) {
          break;
        }
        index++;
      }
      this.state.openTab.splice(index, 1);
    },

    // 设置当前激活的tab
    SET_ACTIVEINDEX(state, index) {
      this.state.activeIndex = index;
    },
  },
  actions: {

  }
});

// export default store;
