import request from "@/utils/http/requset";

function login(data) {
  let url = "/manage/login";
  return request({
    url: url,
    method: "post",
    ContentType: "application/json;charset=UTF-8",
    data: data
  });
}
function getImgCode(data) {
  let url = "/manage/getVerifyCode";
  return request({
    url: url,
    method: "post",
    ContentType: "application/json;charset=UTF-8",
    data: data
  });
}
export default {
  login,
  getImgCode
};
