import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login.vue";
import Main from "../views/Main";

Vue.use(VueRouter);

const routes = [{
  path: "/",
  name: "Login",
  component: Login
}, {
  path: "/Login",
  name: "Login",
  component: Login
}, {
  name: "后台管理",
  path: "/Home",
  redirect: "/Home",
  component: Main,
  children: [{
    name: "home",
    path: "/Home",
    component: resolve => require(["../views/Home.vue"], resolve),
    meta: {
      title: "首页",
      enTitle: "HOME",
      keepAlive: false
    }
  }, {
    name: "Manager",
    path: "/Manager",
    component: resolve => require(["../views/userRole/Manager.vue"], resolve),
    meta: {
      title: "用户管理",
      enTitle: "Manager",
      keepAlive: false
    }
  }, {
    name: "Permission",
    path: "/Permission",
    component: resolve => require(["../views/userRole/Permission.vue"], resolve),
    meta: {
      title: "权限管理",
      enTitle: "Permission",
      keepAlive: false
    }
  }, {
    name: "Role",
    path: "/Role",
    component: resolve => require(["../views/userRole/Role.vue"], resolve),
    meta: {
      title: "角色管理",
      enTitle: "Role",
      keepAlive: false
    }
  }, {
    name: "loanAccountConfig",
    path: "/loanAccountConfig",
    component: resolve => require(["../views/routerConfig/loanAccountConfig.vue"], resolve),
    meta: {
      title: "放款账号配置",
      enTitle: "Loan Account Config",
      keepAlive: false
    }
  }, {
    name: "refundAccountConfig",
    path: "/refundAccountConfig",
    component: resolve => require(["../views/routerConfig/refundAccountConfig.vue"], resolve),
    meta: {
      title: "还款账号配置",
      enTitle: "Refund Account Config",
      keepAlive: false
    }
  }, {
    name: "merchantsRouterConfig",
    path: "/merchantsRouterConfig",
    component: resolve => require(["../views/routerConfig/merchantsRouterConfig.vue"], resolve),
    meta: {
      title: "商户管理",
      enTitle: "Merchants Config",
      keepAlive: false
    }
  }, {
    name: "refundOrder",
    path: "/refundOrder",
    component: resolve => require(["../views/orderManage/refundOrder.vue"], resolve),
    meta: {
      title: "代收订单",
      enTitle: "Refund Order",
      keepAlive: false
    }
  }, {
    name: "lengOrder",
    path: "/lengOrder",
    component: resolve => require(["../views/orderManage/lengOrder.vue"], resolve),
    meta: {
      title: "代付订单",
      enTitle: "Leng Order",
      keepAlive: false
    }
  }, {
    name: "refundErrorOrder",
    path: "/refundErrorOrder",
    component: resolve => require(["../views/orderManage/refundErrorOrder.vue"], resolve),
    meta: {
      title: "代收异常订单",
      enTitle: "Error Order",
      keepAlive: false
    }
  }, {
    name: "lendErrorOrder",
    path: "/lendErrorOrder",
    component: resolve => require(["../views/orderManage/lendErrorOrder.vue"], resolve),
    meta: {
      title: "代付异常订单",
      enTitle: "Error Order",
      keepAlive: false
    }
  }, {
    name: "replenishment",
    path: "/replenishment",
    component: resolve => require(["../views/orderManage/replenishment.vue"], resolve),
    meta: {
      title: "补单",
      enTitle: "Error Order",
      keepAlive: false
    }
  }, {
    name: "merchantPayRate",
    path: "/merchantPayRate",
    component: resolve => require(["../views/merchantPayConfig/merchantPayRate.vue"], resolve),
    meta: {
      title: "支付成功率",
      enTitle: "Error Order",
      keepAlive: false
    }
  }, {
    name: "merchantPayIn",
    path: "/merchantPayIn",
    component: resolve => require(["../views/merchantPayConfig/merchantPayIn.vue"], resolve),
    meta: {
      title: "代收分流",
      enTitle: "Error Order",
      keepAlive: false
    }
  }, {
    name: "merchantPayRouter",
    path: "/merchantPayRouter",
    component: resolve => require(["../views/merchantPayConfig/merchantPayRouter.vue"], resolve),
    meta: {
      title: "代收路由",
      enTitle: "Error Order",
      keepAlive: false
    }
  }, {
    name: "merchantWithdrawRouter",
    path: "/merchantWithdrawRouter",
    component: resolve => require(["../views/merchantPayConfig/merchantWithdrawRouter.vue"], resolve),
    meta: {
      title: "代付路由",
      enTitle: "Error Order",
      keepAlive: false
    }
  }, {
    name: "payRate",
    path: "/payRate",
    component: resolve => require(["../views/payConfig/PayRate.vue"], resolve),
    meta: {
      title: "商户代收成功率",
      enTitle: "Error Order",
      keepAlive: false
    }
  }, {
    name: "payRouter",
    path: "/payRouter",
    component: resolve => require(["../views/payConfig/PayRouter.vue"], resolve),
    meta: {
      title: "商户代收路由",
      enTitle: "Error Order",
      keepAlive: false
    }
  }, {
    name: "payWithdraw",
    path: "/payWithdraw",
    component: resolve => require(["../views/payConfig/WithdrawRouter.vue"], resolve),
    meta: {
      title: "商户代付路由",
      enTitle: "Error Order",
      keepAlive: false
    }
  }, {
    name: "payManyTimesErrorOrder",
    path: "/payManyTimesErrorOrder",
    component: resolve => require(["../views/orderManage/payManyTimesErrorOrder.vue"], resolve),
    meta: {
      title: "代付多次异常订单",
      enTitle: "Error Order",
      keepAlive: false
    }
  },
  {
    name: "merchantPayOut",
    path: "/merchantPayOut",
    component: resolve => require(["../views/merchantPayConfig/merchantPayOut.vue"], resolve),
    meta: {
      title: "代付分流",
      enTitle: "Error Order",
      keepAlive: false
    }
  }, {
    name: "merchantPayCryptoRouter",
    path: "/merchantPayCryptoRouter",
    component: resolve => require(["../views/merchantPayConfig/merchantPayCryptoRouter.vue"], resolve),
    meta: {
      title: "虚拟币代收分流",
      enTitle: "Error Order",
      keepAlive: false
    }
  }, {
    name: "merchantWithdrawCryptoRouter",
    path: "/merchantWithdrawCryptoRouter",
    component: resolve => require(["../views/merchantPayConfig/merchantWithdrawCryptoRouter.vue"], resolve),
    meta: {
      title: "虚拟币代付分流",
      enTitle: "Error Order",
      keepAlive: false
    }
  }, {
    name: "payCryptoWithdraw",
    path: "/payCryptoWithdraw",
    component: resolve => require(["../views/payConfig/payCryptoWithdraw.vue"], resolve),
    meta: {
      title: "商户虚拟币代付分流",
      enTitle: "Error Order",
      keepAlive: false
    }
  }, {
    name: "payCryptoRouter",
    path: "/payCryptoRouter",
    component: resolve => require(["../views/payConfig/payCryptoRouter.vue"], resolve),
    meta: {
      title: "商户虚拟币代收分流",
      enTitle: "Error Order",
      keepAlive: false
    }
  }, {
    name: "payoutAuditOrder",
    path: "/payoutAuditOrder",
    component: resolve => require(["../views/orderManage/payoutAuditOrder.vue"], resolve),
    meta: {
      title: "承兑审核",
      enTitle: "Error Order",
      keepAlive: false
    }
  }, {
    name: "payoutAuditOrderApply",
    path: "/payoutAuditOrderApply",
    component: resolve => require(["../views/orderManage/payoutAuditOrderApply.vue"], resolve),
    meta: {
      title: "承兑申请",
      enTitle: "Error Order",
      keepAlive: false
    }
  }, {
    name: "reportBigTb",
    path: "/reportBigTb",
    component: resolve => require(["../views/report/reportBigTb.vue"], resolve),
    meta: {
      title: "大额实时同比",
      enTitle: "Error Order",
      keepAlive: false
    }
  }, {
    name: "reportTb",
    path: "/reportTb",
    component: resolve => require(["../views/report/reportTb.vue"], resolve),
    meta: {
      title: "实时同比",
      enTitle: "Error Order",
      keepAlive: false
    }
  }, {
    name: "reportDayTb",
    path: "/reportDayTb",
    component: resolve => require(["../views/report/reportDayTb.vue"], resolve),
    meta: {
      title: "按日同比",
      enTitle: "Error Order",
      keepAlive: false
    }
  }, {
    name: "fileUpload",
    path: "/fileUpload",
    component: resolve => require(["../views/orderManage/fileUpload.vue"], resolve),
    meta: {
      title: "上传对账单",
      enTitle: "Error Order",
      keepAlive: false
    }
  },
  ]
}];

const router = new VueRouter({
  mode: "history",
  routes
});
export default router;
