import request from "@/utils/http/requset";
function getMenu(data) {
  let url = "/manage/permission/user";
  return request({
    url: url,
    method: "post",
    data: data,

    // dataType: "jsonp"
  });
}

// 退出登录

function layout(data) {
  let url = "/manage/logout";
  return request({
    url: url,
    method: "post",
    data: data,
    dataType: "jsonp"
  });
}



// 修改密码
function changePassword(data) {
  let url = "/manage/manager/change";
  return request({
    url: url,
    method: "post",
    data: data,
    dataType: "jsonp"
  });
}

function getHomeDataApi(data) {
  let url = "/manage/dashboard/statistics";
  return request({
    url: url,
    method: "post",
    data: data,
  });
}
export default {
  getMenu,
  layout,
  changePassword,
  getHomeDataApi
};
