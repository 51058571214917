import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// 引入elementUI库
import elementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "default-passive-events";

// 预览图片
import "viewerjs/dist/viewer.css";
import Viewer from "v-viewer";

// 引入moment.js
import moment from "moment"; // 导入模块
moment.locale("zh-cn");
Vue.prototype.$moment = moment;
Vue.use(Viewer, {
  defaultOptions: {
    zIndex: 9999,
    inline: false,
    button: true,
    navbar: false,
    title: false,
    toolbar: true,
    tooltip: false,
    movable: true,
    zoomable: true,
    rotatable: true,
    scalable: false,
    transition: true,
    fullscreen: false,
    keyboard: false
  }
});

// 多语言
import i18n from "./tools/i18n";
Vue.use(elementUI);

Vue.config.productionTip = false;
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");
