<template>
  <el-container class="home-container">
    <el-container>
      <!-- 侧边栏 -->
      <el-aside width="220px">
        <div class="aside-title">{{ name }}后台管理系统</div>
        <el-menu
          background-color="#3D3F42"
          text-color="#fff"
          active-text-color="#D5A001"
          unique-opened
          :collapse-transition="false"
          router
          :default-active="isActive"
        >
          <template v-for="(item, index) in menuList">
            <el-submenu
              v-if="item.children && item.children.length"
              :index="index + ''"
              :key="index"
            >
              <template slot="title">
                <i class="el-icon-menu"></i>
                {{ item.name }}
              </template>
              <el-menu-item
                v-for="node in item.children"
                :key="node.url"
                :index="node.url"
              >
                <i class="el-icon-document"></i>
                {{ node.name }}
              </el-menu-item>
            </el-submenu>
            <el-menu-item v-else :index="item.url">
              <i class="el-icon-document"></i>
              {{ item.name }}
            </el-menu-item>
          </template>
        </el-menu>
      </el-aside>
      <el-main>
        <div class="topTitle">
          <div class="userImg">
            <el-dropdown trigger="click">
              <img src="../assets/img/user.png" alt />
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="changePassword"
                  >修改密码</el-dropdown-item
                >
                <el-dropdown-item @click.native="refresh"
                  >刷新系统</el-dropdown-item
                >
                <el-dropdown-item @click.native="layout">退出</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
        <div class="bottomTab">
          <el-tabs
            v-model="activeIndex"
            type="border-card"
            closable
            @tab-remove="tabRemove"
            @tab-click="tabClick"
          >
            <el-tab-pane
              v-for="item in openTab"
              :key="item.name"
              :label="item.title"
              :name="item.route"
            >
              <router-view v-if="test(item.route)"></router-view>
            </el-tab-pane>
          </el-tabs>
        </div>
        <!-- 路由占位符 -->
        <!-- <router-view></router-view> -->
      </el-main>
      <el-dialog
        title="修改密码"
        :close-on-click-modal="false"
        :visible.sync="changePasswordDialogForm"
      >
        <el-form
          :model="changeForm"
          :rules="rules"
          ref="ruleForm"
          label-width="auto"
        >
          <el-form-item label="旧密码：" prop="oldPassword">
            <el-input
              type="password"
              show-password
              v-model="changeForm.oldPassword"
            ></el-input>
          </el-form-item>
          <el-form-item label="新密码：" prop="newPassword">
            <el-input
              type="password"
              show-password
              v-model="changeForm.newPassword"
            ></el-input>
          </el-form-item>
          <el-form-item label="确认密码" prop="repeatPassword">
            <el-input
              type="password"
              show-password
              v-model="changeForm.repeatPassword"
            ></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="changePasswordDialogForm = false">取消</el-button>
          <el-button type="primary" @click="changeSubmit">确认</el-button>
        </div>
      </el-dialog>
    </el-container>
  </el-container>
</template>

<script>
import api from "../api/home";
import DataUtil from "../utils/config/dataUtil";
export default {
  components: {},
  data() {
    var validatePass1 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入原密码"));
      } else {
        if (this.changeForm.oldPassword !== "") {
          callback();
        }
      }
    };
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入新密码"));
      } else {
        if (this.changeForm.newPassword !== "") {
          callback();
        }
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.changeForm.newPassword) {
        callback(new Error("两次输入密码不一致"));
      } else {
        callback();
      }
    };
    return {
      name: "支付",
      changePasswordDialogForm: false,
      changeForm: {
        oldPassword: "",
        newPassword: "",
        repeatPassword: "",
      },
      rules: {
        oldPassword: [{ validator: validatePass1, trigger: "blur" }],
        newPassword: [{ validator: validatePass, trigger: "blur" }],
        repeatPassword: [{ validator: validatePass2, trigger: "blur" }],
      },
      menuList: [],
      isActive: "",
    };
  },
  computed: {
    openTab() {
      return this.$store.state.openTab;
    },
    activeIndex: {
      get() {
        return this.$store.state.activeIndex;
      },
      set(val) {
        this.$store.commit("SET_ACTIVEINDEX", val);
      },
    },
  },
  watch: {
    $route(to) {
      let flag = false;
      for (let item of this.openTab) {
        if (item.route == to.path) {
          this.$store.commit("SET_ACTIVEINDEX", to.path);
          flag = true;
          break;
        }
      }
      if (!flag) {
        this.$store.commit("ADD_TABS", {
          route: to.path,
          name: to.name,
          title: to.meta.title,
        });
        this.$store.commit("SET_ACTIVEINDEX", to.path);
      }
    },
  },
  mounted: function () {
    this.getMainMenu();
    if (this.$route.path == "/Home") {
      this.$store.commit("ADD_TABS", {
        route: this.$route.path,
        name: this.$route.name,
        title: this.$route.meta.title,
      });
      this.$store.commit("SET_ACTIVEINDEX", this.$route.path);
    } else {
      this.$store.commit("SET_ACTIVEINDEX", "/Home");
      this.$router.push("/Home");
    }
  },
  methods: {
    // 刷新系统
    refresh() {
      this.$router.go(0);
    },

    // 修改密码
    changePassword() {
      this.changePasswordDialogForm = true;
      this.$refs.changeForm && this.$refs.changeForm.resetFields();
    },
    changeSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let _data = {
            content: {
              password: this.changeForm.oldPassword,
              newPassword: this.changeForm.newPassword,
              verPassword: this.changeForm.newPassword,
            },
          };
          api.changePassword(_data).then((res) => {
            if (res.code == "0000") {
              this.$refs.ruleForm.resetFields();
              this.changePasswordDialogForm = false;
              this.$message({
                message: "密码修改成功,请重新登录",
                type: "success",
                onClose: () => {
                  this.$router.replace("/login");
                },
              });
            } else {
              this.$message.error(res.body.message);
            }
          });
        }
      });
    },
    getMainMenu() {
      let data = {};
      api.getMenu(data).then((res) => {
        if (res.code == "0000") {
          if (res.data == null) {
            this.$router.push({ path: "/Login" });
            this.$message.error("登录过期,请重新登录");
          }
          this.menuList = res.data.rows;
          DataUtil.savePermission(res.data.rows);
        } else {
          this.$message.error(res.message);
        }
      });
    },

    // 退出登录
    layout() {
      let data = {};
      api.layout(data).then((res) => {
        if (res.code == "0000") {
          this.$message.success("退出登录成功");
          DataUtil.ticket("");
          DataUtil.visitor("");
          DataUtil.name("");
          this.$router.push({ path: "/Login" });
        }
      });
    },

    // tab标签点击时，切换相应的路由
    tabClick() {
      this.$router.push({
        path: this.activeIndex,
      });
      this.isActive = this.activeIndex;
    },
    test(str) {
      if (str == this.$store.state.activeIndex) {
        return true;
      } else {
        return false;
      }
    },

    // 移除tab标签
    tabRemove(targetName) {
      // 首页不删
      if (targetName == "/Home") {
        return;
      } else {
        this.$store.commit("DELETE_TABS", targetName);
        if (this.activeIndex === targetName) {
          // 设置当前激活的路由
          if (this.openTab && this.openTab.length >= 1) {
            this.$store.commit(
              "SET_ACTIVEINDEX",
              this.openTab[this.openTab.length - 1].route
            );
            this.$router.push({
              path: this.activeIndex,
            });
          } else {
            this.$router.push({
              path: "/Home",
            });
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/font-icon/iconfont.css";

.home-container {
  height: 100vh;
}

.el-header {
  background: #373d41;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  font-size: 20px;

  > div {
    display: flex;
    align-items: center;

    img {
      width: 40px;
      height: 40px;
    }

    span {
      margin-left: 15px;
    }
  }
}
.block {
  width: 220px;
  height: 15%;
  // border: 1px solid red;
}
.userinfo-inner {
  display: flex;
  align-items: center;
  font-size: 15px;
  color: #fff;

  > span {
    margin-left: 10px;
  }

  > img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
}
.el-menu {
  width: 220px;
  margin-top: 38px;
  overflow-y: auto;
  height: 95%;
}
.el-aside {
  background: #3d3f42;
  position: relative;
  height: 100%;
  width: 220px;
  overflow-x: hidden;

  .aside-title {
    width: 220px;
    height: 38px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(228, 228, 228, 1);
    line-height: 38px;
    text-align: center;
    position: fixed;
    top: 0;
  }
}

.el-menu .el-menu-item:hover {
  background: #dfb431 !important;
}

.el-menu .is-active:hover {
  background: #373d41 !important;
}

.el-menu .el-submenu__title:hover {
  background: #dfb431 !important;
}

.el-main {
  background: rgba(247, 247, 247, 1);
  padding: 0;
}

.el-submenu__title {
  > span {
    margin-left: 15px;
  }
}

.el-menu-item {
  > span {
    margin-left: 15px;
  }
}

.el-submenu .el-submenu__title:hover {
  background: #dfb431;
}

.el-tabs__nav-wrap .el-tabs__nav-scroll .el-tabs__nav .is-active {
  border-top: 3px solid #409eff;
}

.bottomFunction {
  position: absolute;
  bottom: 0;
  width: 220px;
  height: 150px;
  z-index: 100;
  background: #3d3f42;
}

.refresh {
  width: 16px;
  height: 16px;
  position: absolute;
  bottom: 102px;
  left: 46px;
  cursor: pointer;
}

.refreshText {
  position: absolute;
  bottom: 78px;
  left: 30px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(228, 228, 228, 1);
  line-height: 17px;
  cursor: pointer;
}

.changePass {
  position: absolute;
  width: 16px;
  height: 16px;
  bottom: 102px;
  right: 46px;
  cursor: pointer;
}

.changePassText {
  position: absolute;
  bottom: 78px;
  right: 30px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(228, 228, 228, 1);
  line-height: 17px;
  cursor: pointer;
}

.layout {
  position: absolute;
  bottom: 20px;
  left: 11px;
  width: 194px;
  height: 32px;
  background: rgba(86, 88, 92, 1);
  border-radius: 16px;
  text-align: center;
  font-size: 14px;
  line-height: 32px;
  color: rgba(228, 228, 228, 1);
  cursor: pointer;
}
.topTitle {
  width: 100%;
  height: 38px;
  background: rgb(61, 63, 66);
  display: flex;
  justify-content: flex-end;
}
.userImg {
  margin-right: 25px;
  width: 30px;
  height: 30px;
  margin-top: 3px;
  border-radius: 50%;
  overflow: hidden;
}
.userImg1 {
  margin-right: 25px;
  width: 30px;
  height: 30px;
  margin-top: 3px;
  height: 30px;
}
.imgSize {
  width: 30px;
  height: 30px;
  display: block;
}
.userImg img {
  display: block;
  width: 30px;
  height: 30px;
}
.bottomTab {
  padding: 0 0px;
}
.el-tab-pane {
  width: 100%;
  height: 100%;
}
.languageChange {
  width: 194px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
}
.el-tabs--border-card {
  border: none;
}
.languageChange span {
  width: 30%;
  font-size: 14px;
  color: rgba(228, 228, 228, 1);
  text-align: center;
  cursor: pointer;
}
.languageChange span.active {
  color: #dfb431;
}
::-webkit-scrollbar-track-piece {
  background-color: #f8f8f8;
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius: 2em;
}
/*滚动条的宽度*/
::-webkit-scrollbar {
  width: 9px;
}
/*滚动条的设置*/
::-webkit-scrollbar-thumb {
  background-color: #dddddd;
  background-clip: padding-box;
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius: 2em;
}
/*滚动条鼠标移上去*/
::-webkit-scrollbar-thumb:hover {
  background-color: #bbb;
}
</style>
